<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                	<!-- 框 -->
                    <v-alert :dismissible="false" class="green accent-4 white--text" dark border="left" icon="mdi-key-change white--text">
                    	<v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10">
                                <span>Symbols</span>
                                <span class="ml-5"></span>
                            </v-col>
                            <v-col cols="2" class="text-right">
                                <v-btn tile small outlined color="white" @click="showAddDialog">New</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="text-right mb-8">
					<v-row>
                        <v-col cols="auto">
                            <v-select class="fs-12" dense v-model="query.exchange" label="Exchange" item-value="code" item-text="name" :items="exchanges" clearable></v-select>
                        </v-col>
                        <v-col cols="auto">
                            <v-select class="fs-12" dense v-model="query.tradingCategory" label="Category" :items="tradingCategories" clearable></v-select>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn small tile class="green accent-4 white--text" @click="queryList">Search</v-btn>
                        </v-col>
                    </v-row>
				</v-col>
				<v-col cols="12">
					<v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
					<!-- 表头 -->
					<v-data-table dense :headers="headers" :items="items" hide-default-footer v-if="!loading">
						<!-- 表数据 -->
						<template v-slot:body="{ item }">
							<tbody>
								<tr v-for="(item, index) in items" :key="item.id">
									<td>{{ item.exchange }}</td>
									<td>{{ item.tradingCategory }}</td>
									<td>{{ item.symbol }}</td>
									<td>{{ item.exchangeSymbol }}</td>
									<td>{{ item.baseCurrency }}</td>
									<td>{{ item.quoteCurrency }}</td>
									<td>{{ item.minSize }}</td>
									<td>{{ item.sizeDecimalPlace }}</td>
									<td>{{ item.sizeIncrement }}</td>
									<td>{{ item.priceDecimalPlace }}</td>
									<td>{{ item.priceIncrement }}</td>
									<td>{{ item.faceValue }}</td>
									<td>{{ common.formatTime(item.timestamp) }}</td>
								</tr>
								<tr v-if="totalElements == 0">
                                	<td class="text-center" colspan="17">No Data Available</td>
                            	</tr>
							</tbody>
						</template>
					</v-data-table>
					<v-divider v-if="!loading"></v-divider>
					<!-- 底部分页 -->
					<template>
						<div class="text-xs-center mt-5" v-if="totalElements > 0">
							<v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" circle v-model="pageNo" color="green accent-4" :length="totalPage" @input="getList" :total-visible="15"></v-pagination>
						</div>
					</template>
				</v-col>
            </v-row>
        </v-container>
        <!-- 新增 -->
		<v-dialog v-model="addDialog" persistent max-width="500px">
		    <!-- 模态框 -->
		    <v-card>
		        <v-card-title>
		            <span class="fs-24">{{ addDialogTitle }}</span>
		            <v-spacer></v-spacer>
		            <v-btn @click="addDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
		        </v-card-title>
		        <v-card-text>
		            <v-container>
		                <v-form ref="addForm" v-model="addFormValid">
		                    <v-row>
		                        <v-col cols="6">
		                            <v-select dense v-model="addForm.exchange" label="Exchange" item-value="code" item-text="name" :items="exchanges" clearable></v-select>
		                        </v-col>
		                        <v-col cols="6">
		                            <v-select dense v-model="addForm.tradingCategory" label="Category" :items="tradingCategories" clearable></v-select>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.symbol" label="ioBots Symbol" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.exchangeSymbol" label="Exchange Symbol" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.baseCurrency" label="Base Currency" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.quoteCurrency" label="Quote Currency" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.faceValue" label="Face Value" type="number" :min="0" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.minSize" label="Min Size" type="number" :min="0" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.sizeDecimalPlace" label="Size Decimal Place" type="number" :step="1" :min="0" required clearable></v-text-field>
		                        </v-col>
								<v-col cols="6">
		                        	<v-text-field dense v-model="addForm.sizeIncrement" label="Size Increment" type="number" :min="0" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="addForm.priceDecimalPlace" label="Price Decimal Place" type="number" :step="1" :min="0" required clearable></v-text-field>
		                        </v-col>
								<v-col cols="6">
		                        	<v-text-field dense v-model="addForm.priceIncrement" label="Price Increment" type="number" :min="0" required clearable></v-text-field>
		                        </v-col>
		                    </v-row>
		                </v-form>
		            </v-container>
		        </v-card-text>
		        <v-card-actions>
		            <v-spacer></v-spacer>
		            <v-btn outlined @click="add" :disabled="!addFormValid" :loading="addLoading">Save</v-btn>
		        </v-card-actions>
		    </v-card>
		</v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers: [
                    { text: 'Exchange', sortable: false },
					{ text: 'Category', sortable: false },
					{ text: 'Symbol',  sortable: false },
					{ text: 'Exchange Symbol', sortable: false },
					{ text: 'Base',  sortable: false },
					{ text: 'Quote',  sortable: false },
					{ text: 'Min Size',  sortable: false },
					{ text: 'Size Decimal Place',  sortable: false },
					{ text: 'Size Increment',  sortable: false },
					{ text: 'Price Decimal Place',  sortable: false },
					{ text: 'Price Increment',  sortable: false },
					{ text: 'Face Value',  sortable: false },
					{ text: 'Time',  sortable: false }
                ],
                query: {
                	exchange: null,
                	tradingCategory: null
                },
                items: [],
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 20,
				loading:false,
				addDialog: false,
				addDialogTitle: '',
				addLoading: false,
				addForm: {
					exchange: null,
					tradingCategory: null,
					symbol: null,
					exchangeSymbol: null,
					baseCurrency: null,
					quoteCurrency: null,
					minSize: 0,
					sizeDecimalPlace: 0,
					sizeIncrement: 0,
					priceDecimalPlace: 0,
					priceIncrement: 0,
					faceValue: 0
				},
            }
        },
        computed: {
            ...mapGetters(['user', 'exchanges', 'tradingCategories']),
            addFormValid:{
            	get(){
            		return this.addForm.exchange != null
            			&& this.addForm.tradingCategory != null
            			&& this.addForm.symbol != null
            			&& this.addForm.exchangeSymbol != null
            			&& this.addForm.baseCurrency != null
            			&& this.addForm.quoteCurrency != null
            			&& this.addForm.quoteCurrency != null;
            	},
            	set(v){

            	}
            }
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
			this.getList(1);
        },
        created() {
           
        },
        methods: {
        	// 查询数据
        	queryList(){
        		this.pageNo = 1;
        		this.getList(1);
        	},
		   	// 获取数据
		   	getList(pageNo) {
			   	this.loading = true;
			   	let api = config.baseUrl + '/symbol/public/symbols?pageNo=' + (pageNo - 1) + '&pageSize=' + this.pageSize;
			   	if (this.query.exchange) {
			   		api = api + '&exchange=' + this.query.exchange;
			   	}
			   	if (this.query.tradingCategory) {
			   		api = api + '&tradingCategory=' + this.query.tradingCategory;
			   	}
		       	this.$http.get(api).then(response => {
		       		let data =  response.data;
		       		this.loading = false;
	               	if (data.code == 200) {
	                   this.items = data.result.content;
	                   this.totalElements = data.result.total;
	                   this.totalPage = Math.ceil(this.totalElements / this.pageSize);
	               	} else {
	               		this.items = [];
	                    this.totalElements = 0;
	                    this.totalPage = 0;
	               	}
	           	})
		   	},
		   	// 添加
		   	add(){
			   if (this.$refs.addForm.validate()) {
			       let api = config.baseUrl + '/symbol/private/addSymbol';
				   this.addLoading = true;
			       this.$http.post(api, this.addForm).then(response => {
			           let data = response.data;
			           if (data.code == 200) {
			               this.$store.dispatch('snackbarMessageHandler', "Success");
						   this.addDialog = false;
			               this.queryList();
						   this.$refs.addForm.reset();
						   this.addLoading = false;
			           } else {
			           		this.$store.dispatch('snackbarMessageHandler', data.message);
			           		this.$refs.addForm.reset();
			           		this.addLoading = false;
			           }
			       }, error => {
			           this.$store.dispatch('snackbarMessageHandler', "Error");
			           this.addLoading = false;
			       });
			   }
		   },
		   // 显示新增弹窗
		   showAddDialog(){
				this.addDialogTitle = 'New';
				this.addForm = {
					exchange: null,
					tradingCategory: null,
					symbol: null,
					exchangeSymbol: null,
					baseCurrency: null,
					quoteCurrency: null,
					minSize: 0,
					sizeDecimalPlace: 0,
					sizeIncrement: 0,
					priceDecimalPlace: 0,
					priceIncrement: 0,
					faceValue: 0
				};
				this.addDialog = true;
		   },
        }
    }
</script>

<style>

</style>